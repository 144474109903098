<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <div :class="$style.left">
        <Input
          :class="$style.inputTitle"
          v-model="data.title"
          types="primary"
          placeholder="Введите заглавие"
          maxlength="59"
        />
        <Textarea
          :class="$style.textarea"
          v-model="data.description"
          placeholder="Введите описание"
          maxlength="400"
          rows="5"
        />
      </div>
      <div :class="$style.right">
        <div :class="$style.wrapperInput">
          <Input
            placeholder="Ваш телефон"
            :class="$style.inputQuestion"
          />
          <Button :class="$style.button" type="primary">отправить</Button>
        </div>
        <div :class="$style.policy">
          <p>
            Нажатием кнопки «отправить» я даю свое
            <b>согласие на обработку персональных данных</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/atoms/Input";
import Button from "@/components/atoms/Button";
import Textarea from "@/components/atoms/Textarea";

export default {
  components: {
    Input,
    Button,
    Textarea,
  },

  props:{
    data: Object
  }
};
</script>

<style lang="scss" module>

.container {
  width: 100%;
  background: $white;
  padding: 3rem 1rem;

  .wrapper {
    max-width: 82rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      width: 53%;

      .inputTitle {
        font-size: 1.25rem;
        background: transparent;
        margin: 0;
      }

      .textarea {
        background: transparent;
        color: $dark-gray;
      }
    }

    .right {
      width: 45%;

      .wrapperInput {
        display: flex;
        width: 100%;
        padding: 0.5rem 0 0.5rem;

        .inputQuestion {
          padding: 1.5rem;
          margin: 0;
        }
        .button {
          width: 30%;
        }
      }

      .policy {
        text-align: right;
        font-size: 0.75rem;
        color: $dark-gray;
      }
    }
  }
}
</style>
