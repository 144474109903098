<template>
  <div :class="$style.container">
    <ImageUploader
      :class="$style.cover"
      v-model="data.cover"
      text="Перетащите или нажмите для выбора фото"
      multiple
      :autoplay-speed="parseInt(data.autoplaySpeed) || undefined"
    />
    <ImageUploader
      :class="$style.logo"
      v-model="data.logo"
      text="Добавить фото бренда"
    />
    <HeadCard :class="$style.card" v-model="data.card" />
    <Textarea
      :class="$style.title"
      type="primary"
      placeholder="Введите заглавие"
      v-model="data.title"
      maxlength="100"
    />
    <Textarea
      :class="$style.descriptions"
      placeholder="Введите описание"
      v-model="data.description"
      maxlength="845"
      rows="8"
    />
    <Textarea
      :class="$style.autoplaySpeed"
      placeholder="Интервал пролистывания (мс)"
      v-model="data.autoplaySpeed"
      rows="3"
    />
    <Textarea
      :class="$style.descriptionsLogo"
      placeholder="Описание картинки логотипа"
      v-model="data.descriptionLogo"
      rows="3"
    />
    <Textarea
      :class="$style.descriptionCover"
      placeholder="Описание основной картинки"
      v-model="data.descriptionCover"
      rows="3"
    />
  </div>
</template>

<script>
import Textarea from '@/components/atoms/Textarea'
import HeadCard from '@/components/atoms/cards/HeadCard'
import ImageUploader from '@/components/moleculs/ImageUploader'

export default {
  components: {
    Textarea,
    HeadCard,
    ImageUploader,
  },

  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  max-width: 80rem;
  margin: auto;

  .cover {
    width: 100%;
    height: 33rem;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.15));

    img {
      height: 33rem;
    }
  }

  .logo {
    position: absolute;
    top: 0;
    left: 2%;
    z-index: 9;
    width: 20rem;
    height: 20rem;
  }

  .card {
    position: absolute;
    top: 40%;
    right: 2%;
  }

  .title {
    width: 70%;
  }

  .descriptions {
    width: 50%;
  }

  .autoplaySpeed,
  .descriptionsLogo,
  .descriptionCover {
    width: 16%;
  }
}
</style>
