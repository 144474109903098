<template>
  <div :class="$style.container">
    <div :class="$style.pickup">
      <h4 :class="$style.title">Самовывоз</h4>
      <div :class="$style.time">
        <Icon name="clock" :class="$style.icon" />
        <span>{{ content?.showroom?.workingHours }}</span>
      </div>
      <div :class="$style.takeInfo">
        <span>Вы можете забрать:</span>
        <Input
          v-model="data.description"
          types="secondary"
          placeholder="Введите название товара"
          maxlength="32"
          :class="$style.input"
        />
      </div>
      <div :class="$style.address">
        <span> по адресу:</span>
        <span>{{ fullAdress }}</span>
      </div>
    </div>
    <div :class="$style.map">
      <Map
        :longitude="Number(content?.showroom?.mapCoordinates?.lng)"
        :latitude="Number(content?.showroom?.mapCoordinates?.lat)"
      />
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'

import Map from '@/components/atoms/Map'
import Input from '@/components/atoms/Input'
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    Icon,
    Map,
    Input
  },
  data() {
    return {
      content: {}
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    fullAdress() {
      return (
        `${this.content?.showroom?.address?.street}, ${this.content?.showroom?.address?.state}, ${this.content?.showroom?.address?.postCode}, ${this.content?.showroom?.address?.city}` ??
        ''
      )
    }
  },
  async created() {
    await this.getContent()
  },
  methods: {
    async getContent() {
      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'contacts',
          'addwine'
        )

      if (error) return

      this.content = value.content
    }
  }
}
</script>

<style lang="scss" module>
.container {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  .pickup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    min-height: 18rem;
    background: $white;
    padding: 2rem 1rem;

    .title {
      @include title();
      color: $black;
    }

    .time {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .icon {
        width: 1.75rem;
      }
    }

    .address {
      display: flex;
      flex-direction: column;
    }

    .input {
      margin: 0;
    }
  }

  .map {
    width: 100%;
    min-height: 18rem;
    background: $white;
  }
}
</style>
