<template>
  <div :class="$style.container">
    <Input
      :class="$style.title"
      types="primary"
      placeholder="Введите заглавие"
      maxlength="65"
      v-model="data.title"
    />
    <ProductForm v-model="data.products"></ProductForm>
  </div>
</template>
<script>
import Input from '@/components/atoms/Input'
import ProductForm from '@/components/organisms/ProductFormIII.vue'

export default {
  components: { ProductForm, Input },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" module>
.container {
  max-width: 82rem;
  padding: 3rem 1rem 5rem;
  margin: 0 auto;

  .title {
    display: flex;
    align-items: center;
    margin: 0 0 2rem;
  }
}
</style>
