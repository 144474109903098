<template>
  <div :class="$style.container">
    <div :class="$style.fon"></div>
    <div :class="$style.content">
      <div :class="$style.text">
        <p :class="$style.design">Конструкция</p>
        <div :class="$style.switching">
          <Button :class="$style.left" border="black" @click="showPrev">
            <Icon name="arrow" />
          </Button>
          <Button :class="$style.right" border="black" @click="showNext">
            <Icon name="arrow" />
          </Button>
          <div :class="$style.name">
            <VueSlickCarousel ref="carouselName" v-bind="carouselName">
              <div :class="$style.slider">
                <Input
                  types="primary"
                  :class="$style.inputName"
                  placeholder="Введите заглавие"
                  v-model="data.titleFirst"
                  maxlength="23"
                />
              </div>
              <div :class="$style.slider">
                <Input
                  types="primary"
                  :class="$style.inputName"
                  placeholder="Введите заглавие"
                  v-model="data.titleSecond"
                  maxlength="23"
                />
              </div>
              <div :class="$style.slider">
                <Input
                  types="primary"
                  :class="$style.inputName"
                  placeholder="Введите заглавие"
                  v-model="data.titleThird"
                  maxlength="23"
                />
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div :class="$style.descriptions">
          <VueSlickCarousel ref="carouselText" v-bind="carouselText">
            <div :class="$style.slider">
              <Textarea
                :class="$style.description"
                placeholder="Введите описание"
                v-model="data.descriptionFirst"
                maxlength="350"
              />
            </div>
            <div :class="$style.slider">
              <Textarea
                :class="$style.description"
                placeholder="Введите описание"
                v-model="data.descriptionSecond"
                maxlength="350"
              />
            </div>
            <div :class="$style.slider">
              <Textarea
                :class="$style.description"
                placeholder="Введите описание"
                v-model="data.descriptionThird"
                maxlength="350"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div :class="$style.photo">
        <VueSlickCarousel ref="carouselPhoto" v-bind="carouselPhoto">
          <div>
            <ImageUploader
                :class="$style.slider"
                v-model="data.imageFirst"
                text="Перетащите или нажмите для выбора фото"
            />
          </div>
          <div>
            <ImageUploader
                :class="$style.slider"
                v-model="data.imageSecond"
                text="Перетащите или нажмите для выбора фото"
            />
          </div>
          <div>
            <ImageUploader
                :class="$style.slider"
                v-model="data.imageThird"
                text="Перетащите или нажмите для выбора фото"
            />
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/atoms/Icon";
import Button from "@/components/atoms/Button";
import Input from "@/components/atoms/Input";
import Textarea from "@/components/atoms/Textarea";
import ImageUploader from "@/components/moleculs/ImageUploader"
import VueSlickCarousel from "vue-slick-carousel";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";


export default {
  components: {
    Icon,
    VueSlickCarousel,
    Button,
    Input,
    Textarea,
    ImageUploader,
  },

  props: {
    data: Object
  },

  data() {
    return {
      carouselText: {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
      },

      carouselPhoto: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
      },

      carouselName: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
      },
    };
  },

  methods: {
    showNext() {
      this.$refs.carouselPhoto.next();
      this.$refs.carouselText.next();
      this.$refs.carouselName.next();
    },
    showPrev() {
      this.$refs.carouselPhoto.prev();
      this.$refs.carouselText.prev();
      this.$refs.carouselName.prev();
    },
  },
};
</script>

<style lang="scss">

.slick-dots {
  right: 40%;
}

.slick-dots li button:before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background: $black-gray;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  background: $bright-gold;
}
</style>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40rem;
  position: relative;

  .fon {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 70%;
    height: 100%;
    background: $white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 82rem;
    min-height: 30rem;
    padding: 0 1rem;
    margin: 0 auto;
    z-index: 1;
    overflow: hidden;

    .text {
      width: 40%;
      height: 100%;

      .design {
        padding: 0 0 1rem;
        font-weight: bold;
        opacity: 0.4;
        border-bottom: 1px solid $extra-light-gray;
      }

      .switching {
        display: flex;
        align-items: center;
        padding: 3rem 0 0;

        button {
          padding: 1rem 1.2rem;
          svg {
            width: 0.7rem;
            height: 0.7rem;
            color: $extra-light-gray;
          }
        }

        .right {
          transform: rotate(180deg);
          margin: 0 1rem;
        }

        .name {
          width: 80%;

          .slider {
            .inputName {
              background: transparent;
            }
          }
        }
      }

      .descriptions {
        .description {
          background: transparent;
        }
      }

      .slider {
        outline: none;
      }
    }

    .photo {
      width: 55%;
      margin: 0 0.1rem;

      .slider {
        display: flex;
        outline: none;
        height: 33rem;
      }
    }
  }
}
</style>
