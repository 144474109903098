<template>
  <div :class="$style.modalWindow">
    <div :class="$style.window">
      <div :class="$style.header">
        <img
          :class="$style.close"
          @click="close"
          src="@/assets/icons/plus.svg"
        />
      </div>
      <img src="@/assets/icons/modalStatusOk.svg" />
      <h1 :class="$style.descriptions">Данные отправлены в базу данных!</h1>
      <Button @click="close" :class="$style.modalButton" type="primary">
        OK
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '../atoms/Button'

import { mapMutations } from 'vuex'

export default {
  components: {
    Button,
  },

  methods: {
    ...mapMutations(['changePublicationStatusFalse']),

    close() {
      this.changePublicationStatusFalse()
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.modalWindow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(15, 47, 62, 0.8),
    rgba(15, 47, 62, 0.8)
  );

  .window {
    width: 45%;
    min-height: 70%;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 3rem;
      background: $banner;

      .close {
        margin: 0 1.5rem;
        width: 1rem;
        transform: rotate(45deg);
        cursor: pointer;
      }
    }

    .descriptions {
      text-align: center;
      margin: 3rem 0;
      width: 90%;
    }

    .modalButton {
      padding: 1.5rem 7rem;
      margin: 0 0 2rem;
    }
  }
}
</style>
