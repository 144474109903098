<template>
  <div :class="$style.addWineFeedbacks">
    <div v-for="(feedback, index) in adwFeedbacks" :key="index">
      <div :class="$style.wrapper">
        <div :class="$style.aboutClient">
          <div :class="$style.image">
            <img
              :src="$configData.s3_link + feedback.image"
              alt="feedback author photo"
            />
          </div>
          <div :class="$style.info">
            <h4>{{ feedback.name }}</h4>
            <p>{{ feedback.jobTitle }}</p>
            <div :class="$style.icons">
              <adw-social-icon
                v-for="icon in feedback.socials"
                :key="icon.iconName"
                :name="icon.iconName"
                :link="icon.link"
              />
            </div>
          </div>
        </div>
        <div :class="$style.feedback">
          {{ feedback.text }}
          <div :class="$style.pointer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'

export default {
  components: {},
  props: {
    showAddWineFeedbacks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      feedbacks: [],
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slideToScroll: 1,
        speed: 700
      }
    }
  },
  async created() {
    await this.getAddWineFeedbacks()
  },
  computed: {
    adwFeedbacks() {
      return this.showAddWineFeedbacks ? this.feedbacks : []
    }
  },
  methods: {
    async getAddWineFeedbacks() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get('common', 'addwine')
      loading.close()
      if (error) return
      this.feedbacks = value.content.reviewsSection.reviews.map((feedback) => ({
        name: feedback.name,
        jobTitle: feedback.jobTitle,
        image: feedback.image,
        text: feedback.text,
        socials: feedback.socials
      }))
    }
  }
}
</script>

<style lang="scss" module>
.addWineFeedbacks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  .wrapper {
    max-width: 18.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &.flexWrapper {
      flex-direction: row;
      max-width: 100%;
      .feedback {
        margin-left: 1rem;
        width: 100%;
      }
    }
    @include mobile {
      max-width: 17.5rem;
    }
    .aboutClient {
      display: flex;
      flex: 1 1 auto;
      min-height: 9.25rem;
      &.flexWrapper {
        flex-direction: column-reverse;
        .image {
          width: 7.75rem;
          height: 7.75rem;
        }
      }
      .image {
        width: 3.75rem;
        height: 3.75rem;
        margin-right: 0.8rem;
        flex: 0 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .info {
        h4 {
          margin-bottom: 0.75rem;
        }
        p {
          color: $gray;
          margin-bottom: 0.75rem;
        }
        .icons {
          display: flex;
          margin-bottom: 2rem;
          a:not(:last-child) {
            margin-right: 0.75rem;
          }
        }
      }
    }
    .feedback {
      max-height: 18rem;
      height: 100%;
      font-size: 0.9375rem;
      line-height: 1.375rem;
      position: relative;
      color: $gray;
      background-color: $white;
      padding: 1.5rem;
      border: 1px solid $light-gray;
      border-radius: 0.188rem;
      .pointer {
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        background-color: $white;
        top: -0.375rem;
        left: 5%;
        transform: rotate(45deg);
        border: 1px solid $light-gray;
        border-right: none;
        border-bottom: none;
      }
    }
  }
}
</style>
