<template>
  <div>
    <Textarea
      :class="$style.area"
      type="primary"
      placeholder="Введите название бренда"
      maxlength="100"
      v-model="data.name"
      rows="1"
    />
    <p :class="$style.sign">Поле обязательно для заполнения!</p>
    <p>
      Название бренда должно быть написано латиницей, в одно слово с
      возможностью использовать символы "-", "_", строчными буквами, исключая
      системные слова(dev, test, addwine, localhost, new, undefined)
    </p>
    <br />
    <Textarea
      :class="$style.area"
      placeholder="Введите ссылку на страницу бренда в основном портале"
      v-model="data.link"
      rows="1"
    />
    <ImageUploader
      :class="$style.favicon"
      text="Favicon"
      v-model="data.favicon"
    />
    <p :class="$style.sign">Поле обязательно для заполнения!</p>
    <p :class="$style.faviconDescription">
      Рекомендованный размер картинки 260x260px, минимальный 70х70рх, провести
      проверку иконки можно на
      <a href="https://realfavicongenerator.net/" rel="nofollow" target="blank">
        данном источнике</a
      >
    </p>
    <h3>SEO параметры</h3>
    <div :class="$style.row">
      <div>
        <span :class="$style.seo">Заголовок страницы</span>
        <p :class="$style.sign">Поле обязательно для заполнения!</p>
        <Textarea
          :class="$style.area"
          placeholder="Заголовок страницы"
          v-model="data.seoTitle"
          rows="1"
        />
        <span :class="$style.seo">Описание страницы</span>
        <Textarea
          :class="$style.area"
          placeholder="Описание страницы"
          v-model="data.seoDescription"
        />

        <span :class="$style.seo">Ключевые слова</span>
        <Textarea
          :class="$style.area"
          placeholder="Ключевые слова"
          v-model="data.seoKeywords"
        />

        <span :class="$style.seo">Google Аналитика</span>
        <Textarea
          :class="$style.area"
          placeholder="Введите тэг google аналитики"
          v-model="data.gaTag"
        />
        <span :class="$style.seo">Yandex Метрика</span>
        <Textarea
          :class="$style.area"
          placeholder="Введите тэг яндекс метрики"
          v-model="data.ymTag"
        />
        <span :class="$style.seo">Roistat Аналитика</span>
        <Textarea
          :class="$style.area"
          placeholder="Введите ключ счетчика роистат"
          v-model="data.roistatTag"
        />
        <SwitchInput
          v-model="data.mailOnOrder"
          label="Отправлять сообщение при заказе"
        />
      </div>
      <ImageUploader
        :class="$style.image"
        text="Фотография для SEO"
        v-model="data.seoImage"
      />
    </div>
  </div>
</template>

<script>
import Textarea from '@/components/atoms/Textarea'
import SwitchInput from '@/components/atoms/SwitchInput'
import ImageUploader from '@/components/moleculs/ImageUploader'

export default {
  name: 'SystemLandingSection',
  components: {
    ImageUploader,
    SwitchInput,
    Textarea
  },
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss" module>
.sign {
  color: red;
}
.favicon {
  width: 5rem;
  h2 {
    font-size: 1.2rem;
  }
}
.faviconDescription {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  .link {
    text-decoration: none;
    font-style: italic;
  }
}
.row {
  display: flex;
  justify-content: space-between;
  .image {
    width: 25rem;
    height: 15rem;
  }
  .seo {
    font-size: 0.85rem;
    opacity: 0.75;
  }
}
</style>
