<template>
  <div :class="$style.container">
    <div :class="$style.pickup">
      <h1>Самовывоз</h1>
      <span :class="$style.time">
        <Icon style="margin-right: 0.5rem" name="clock" /> пн-сб
        <Input
          :class="$style.nameInput"
          types="secondary"
          placeholder="Укажите время работы hh:mm - hh:mm"
          v-model="data.shopOpeningHours"
        />
      </span>
      <p>
        Вы можете забрать
        <Input
          :class="$style.nameInput"
          types="secondary"
          placeholder="Введите название товара"
          v-model="data.description"
          maxlength="32"
        />
        по адресу:
        <Input
          id="adress"
          :class="$style.nameInput"
          types="secondary"
          placeholder="Введите адресc"
          v-model="data.adress"
        />
      </p>
      <p>
        Введите широту
        <Input
          id="longitude"
          :class="$style.nameInput"
          types="secondary"
          v-model="data.longitude"
        />
      </p>
      <p>
        Введите долготу
        <Input
          id="latitude"
          :class="$style.nameInput"
          types="secondary"
          v-model="data.latitude"
        />
      </p>
      <Button :class="$style.button" type="primary" @click="getDataFromFields"
        >Показать на карте</Button
      >
    </div>
    <div :class="$style.map">
      <Map :longitude="longitude" :latitude="latitude" />
    </div>
  </div>
</template>

<script>
import Map from "@/components/atoms/Map";
import Input from "@/components/atoms/Input";
import Icon from "@/components/atoms/Icon";

export default {
  components: {
    Icon,
    Map,
    Input,
  },

  data() {
    return {
      longitude: null,
      latitude: null,
    };
  },

  props: {
    data: Object,
  },

  methods: {
    getDataFromFields: function () {
      let longitude = document.getElementById("longitude").value;
      this.longitude = Number(longitude);
      let latitude = document.getElementById("latitude").value;
      this.latitude = Number(latitude);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .pickup {
    width: 25%;
    min-height: 18rem;
    background: $white;
    padding: 2rem 1rem;

    .time {
      display: flex;
      align-items: center;
      padding: 0 0 2rem;
    }

    .nameInput {
      margin: 0;
    }
  }

  .map {
    width: 73%;
    min-height: 18rem;
    background: $white;
  }
}
</style>
