<template>
  <component :is="type" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import System from '@/components/organisms/landingSections/System'
import Head from '@/components/organisms/landingSections/Head'
import Advantages from '@/components/organisms/landingSections/Advantages'
import AskExpert from '@/components/organisms/landingSections/AskExpert'
import AskExpertII from '@/components/organisms/landingSections/AskExpertII'
import CallbackForm from '@/components/organisms/landingSections/CallbackForm'
import CallToAction from '@/components/organisms/landingSections/CallToAction'
import Design from '@/components/organisms/landingSections/Design'
import DesignII from '@/components/organisms/landingSections/DesignII'
import Feedbacks from '@/components/organisms/landingSections/Feedbacks'
import Gallery from '@/components/organisms/landingSections/Gallery'
import Map from '@/components/organisms/landingSections/Map'
import MapII from '@/components/organisms/landingSections/MapII'
import ProductOrderIII from '@/components/organisms/landingSections/ProductOrderIII'
import Steps from '@/components/organisms/landingSections/Steps'
import Video from '@/components/organisms/landingSections/Video'

export default {
  name: 'LandingSection',
  components: {
    System,
    Head,
    Advantages,
    AskExpert,
    AskExpertII,
    CallbackForm,
    CallToAction,
    Design,
    DesignII,
    Feedbacks,
    Gallery,
    Map,
    ProductOrderIII,
    Steps,
    Video,
    MapII
  },
  props: {
    type: String
  }
}
</script>
