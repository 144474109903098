<template>
  <yandex-map :coords="changeCoords" :zoom="16" :controls="['zoomControl']">
    <ymap-marker marker-id="123" :coords="changeCoords" :icon="Icon" />
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps"

export default {
  props: {
    longitude: Number,
    latitude: Number
    },
  components: {
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      Icon: {
        layout: "default#imageWithContent",
        imageHref: "/img/location.svg",
        imageSize: [43, 55],
        imageOffset: [-21.5, -55],
      },
    };
  },
  computed: {
    changeCoords() {
      let newCoords = []
      let newlong = this.longitude
      newCoords.push(newlong)
      let newLat = this.latitude
      newCoords.push(newLat)
      return newCoords
    },
  },
};
</script>

<style lang="scss" scoped>
.ymap-container {
  width: 100%;
  height: 100%;
}
</style>
