<template>
  <div :class="$style.modalWindow">
    <div :class="$style.window">
      <div :class="$style.header">
        <Icon
            name="plus"
            :class="$style.close"
            @click="close"
        />
      </div>
      <Icon name="personaRepairing"/>
      <h1 :class="$style.descriptions">
        При перезагрузке или закрытии страницы введённые данные удалятся!
      </h1>
      <Button
        @click="close"
        :class="$style.modalButton"
        type="primary"
      >
        Закрыть
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";

export default {
  components: {
    Button,
    Icon,
  },

  methods: {
    close() {
      this.$store.commit('changeInfoStatusFalse')
    },
  },
};
</script>

<style lang="scss" module>

.modalWindow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(15, 47, 62, 0.8),
    rgba(15, 47, 62, 0.8)
  );

  .window {
    width: 45%;
    min-height: 70%;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 3rem;
      background: $banner;

      .close {
        fill: $light-gray;
        margin: 0 1.5rem;
        width: 1rem;
        transform: rotate(45deg);
        cursor: pointer;
      }
    }

    .descriptions {
      text-align: center;
      margin: 3rem 0;
      width: 90%;
    }

    .modalButton {
      padding: 1.5rem 7rem;
      margin: 0 0 2rem;
    }
  }
}
</style>
