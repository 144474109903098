export default {
  methods: {
    setupImagesListData(images) {
      if (images && images.length && !!images[0]) {
        return {
          original: this.$configData.s3_link + images[0].original,
          caption: images[0].caption
        }
      }
      return { original: `/img/default.webp`, caption: 'image' }
    },
    setupImageData(image) {
      if (image) {
        return {
          original: this.$configData.s3_link + image,
          caption: 'image'
        }
      }

      return { original: `/img/default.webp`, caption: 'image' }
    },
    setDefaultImage(event) {
      event.target.src = `/img/default.webp`
    },
    setGalleryImages(images) {
      if (!images) {
        return []
      }

      return images.map((img) => this.$configData.s3_link + img.original)
    }
  }
}
