<template>
  <div :class="$style.video">
    <div :class="$style.content" v-html="data.link"></div>
    <Icon
      v-if="!data.link"
      :class="$style.play"
      name="play"
    />
    <Input
      :class="$style.inputVideo"
      placeholder="Скопируйте HTML-код со страницы YouTube"
      v-model="data.link"
    />
    <ImageUploader
        :class="$style.preview"
        v-model="data.preview"
        text="Перетащите или нажмите для загрузки превью"
    />
  </div>
</template>

<script>
import Input from "@/components/atoms/Input";
import Icon from "@/components/atoms/Icon";
import ImageUploader from "@/components/moleculs/ImageUploader";

export default {
  components: {
    ImageUploader,
    Icon,
    Input,
  },

  props: {
    data: Object
  }
};
</script>

<style lang="scss" module>
  .video {
    max-width: 85.3rem;
    margin: auto;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(15, 47, 62, 0.8),
      rgba(15, 47, 62, 0.8)
    );
    .content {
      min-height: 35rem;
    }

    .play {
      position: absolute;
      left: 47%;
      bottom: 42%;
      width: 7rem;
      cursor: pointer;
    }
    .preview {
      display: none;
      position: absolute;
      width: 18rem;
      height: 13rem;
      top: 0;
      right: 2%;
    }

    .inputVideo {
      padding: 1.1rem 1.5rem;
      position: absolute;
      bottom: 0;
    }
  }
</style>
