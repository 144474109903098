export const LandingSectionsList = {
  Head: 'Заголовок',
  Advantages: 'Преимущества',
  AskExpert: 'Спросите эксперта 1',
  AskExpertII: 'Спросите эксперта 2',
  CallbackForm: 'Форма обратной связи',
  CallToAction: 'Призыв к действию',
  Design: 'Конструкция',
  DesignII: 'Конструкция v2',
  Feedbacks: 'Отзывы',
  Gallery: 'Галерея',
  Map: 'Карта',
  MapII: 'Карта v2',
  ProductOrderIII: 'Продукты v3',
  Steps: 'Шаги',
  Video: 'Видео'
}
