<template>
  <div :class="$style.adventures">
    <div :class="$style.item">
      <div :class="$style.top">
        <ImageUploader :class="$style.icon" v-model="data.imgLeft" />
        <Textarea
          :class="$style.descriptionImg"
          placeholder="Описание картинки"
          v-model="data.descriptionImgLeft"
        />
      </div>
      <Textarea
        :class="$style.input"
        placeholder="Введите описание"
        v-model="data.descriptionLeft"
        maxlength="100"
        rows="3"
      />
    </div>
    <div :class="$style.item">
      <div :class="$style.top">
        <ImageUploader :class="$style.icon" v-model="data.imgCenter" />
        <Textarea
          :class="$style.descriptionImg"
          placeholder="Описание картинки"
          v-model="data.descriptionImgCenter"
        />
      </div>
      <Textarea
        :class="$style.input"
        placeholder="Введите описание"
        v-model="data.descriptionCenter"
        maxlength="100"
        rows="3"
      />
    </div>
    <div :class="$style.item">
      <div :class="$style.top">
        <ImageUploader :class="$style.icon" v-model="data.imgRight" />
        <Textarea
          :class="$style.descriptionImg"
          placeholder="Описание картинки"
          v-model="data.descriptionImgRight"
        />
      </div>
      <Textarea
        :class="$style.input"
        placeholder="Введите описание"
        v-model="data.descriptionRight"
        maxlength="100"
        rows="3"
      />
    </div>
  </div>
</template>

<script>
import Textarea from "@/components/atoms/Textarea";
import ImageUploader from "@/components/moleculs/ImageUploader";

export default {
  components: {
    ImageUploader,
    Textarea,
  },
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" module>
.adventures {
  display: flex;
  justify-content: space-around;
  max-width: 80rem;
  margin: 1rem auto;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 35%;

    .icon {
      min-height: 5rem;
      min-width: 8rem;
    }

    .input {
      text-align: center;
    }
    .top {
      display: flex;
      justify-content: space-evenly;
      .descriptionImg {
        width: 50%;
      }
    }
  }
}
</style>
