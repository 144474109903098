<template>
  <div :class="$style.container">
    <Button type="primary">
      <Input
        types="secondary"
        :class="$style.inputBanner"
        placeholder="Текст кнопки"
        v-model="data.buttonText"
        maxlength="150"
      />
    </Button>
    <Input
        types="secondary"
        :class="$style.inputBanner"
        placeholder="Введите ссылку для перехода"
        v-model="data.link"
        maxlength="150"
    />
    <Input
      types="secondary"
      :class="$style.inputBanner"
      placeholder="Введите текст"
      v-model="data.description"
      maxlength="150"
    />
  </div>
</template>

<script>
import Button from "@/components/atoms/Button";
import Input from "@/components/atoms/Input";

export default {
  components: {
    Button,
    Input,
  },
  props: {
    data: Object
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  padding: 2rem;
  background: $banner;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputBanner {
    color: $light-gray;
    text-align: center;
  }

  .inputBanner::placeholder {
    color: $extra-light-gray;
  }
}
</style>
