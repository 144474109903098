import delivery from "@/delivery"

class ImageService {
    async uploadFile(files){
        const result = {
            value: null,
            error: null,
        }

        const { value, error } = await delivery.AddwineCore.ImagesActions.sendPhoto(files[0])
        if(error){
            result.error = error
        }
        result.value = value

        return result
    }
    async uploadBulkFiles(files){
        const result = {
            value: null,
            error: null,
        }

        const responses = await Promise.all(Array.from(files).map(file => delivery.AddwineCore.ImagesActions.sendPhoto(file)))

        responses.map(response => {
            if(response.error){
                result.error = response.error
            }
        })

        result.value = responses.map(response => {
            return response.value
        })

        return result
    }
}

export default new ImageService()
