<template>
  <div :class="$style.wrapper">
    <div :class="$style.header" @click="$emit('input', !value)">
      <div>
        <BurgerIcon id="drag-handler" @click.stop="" :class="$style.drag" />
        <ArrowIcon :class="[$style.arrow, { [$style.hide]: !value }]" />
      </div>
      {{ title }}
      <PlusIcon :class="$style.close" @click.stop="$emit('close')" />
    </div>
    <collapse-transition>
      <slot v-if="value" />
    </collapse-transition>
  </div>
</template>

<script>
import BurgerIcon from '@/assets/icons/burger.svg'
import PlusIcon from '@/assets/icons/plus.svg'
import ArrowIcon from '@/assets/icons/arrow.svg'

export default {
  name: 'LandingSectionWrapper',
  components: {
    BurgerIcon,
    PlusIcon,
    ArrowIcon
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    title: String
  }
}
</script>

<style lang="scss" module>
.wrapper {
  max-width: 80rem;
  margin: 1rem auto;
  .header {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.2;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $extra-light-gray;
    color: $black-gray;

    svg {
      height: 1rem;
      width: 1rem;
      fill: $black-gray;
    }
    .drag {
      cursor: move;
      margin-right: 1rem;
    }

    .arrow {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
      &.hide {
        transform: rotate(-180deg);
      }
    }
    .close {
      transform: rotate(45deg);
    }
  }
  &:hover {
    .header {
      opacity: 1;
    }
  }
}
</style>
