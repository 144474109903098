<template>
  <div :class="$style.wrapper">
    <span
      :class="[$style.switch, { [$style.activeSwitch]: checked }]"
      @click="handleClick"
    >
      <div :class="[$style.circle, { [$style.active]: checked }]"></div>
    </span>
    <span
      :class="$style.label"
      @click="handleClick"
    >
      <slot>{{ label }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    label: String,
    checked: Boolean
  },
  methods: {
    handleClick () {
      if (!this.disabled) {
        this.$emit(
          "change", !this.checked
        );
      }
    }
  }
};
</script>

<style lang="scss" module>
@import "@/assets/styles/colors";
@import "@/assets/styles/animations";

.wrapper {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  .switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 2.875rem;
    height: 1.4rem;
    border: 1px solid $dark-gray;
    border-radius: 12px;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
    .circle {
      width: 1.1rem;
      height: 1.1rem;
      background: $dark-gray;
      border-radius: 50%;
      margin: 0.1rem;
      transition: all 0.3s ease-in-out;
    }
    .active {
      background: $white;
      transform: translateX(1.45rem);
    }
  }
  .activeSwitch {
    opacity: 1;
    border: none;
    background: $bright-gold;
  }
  .label {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}
</style>
