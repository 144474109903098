<template>
  <div :class="$style.container">
    <Input
      types="primary"
      :class="$style.titleBlock"
      placeholder="Введите заголовок блока"
      v-model="data.titleBlock"
      maxlength="100"
    />
    <Input
      types="secondary"
      :class="$style.titleBlock"
      placeholder="Введите время в секундах"
      v-model="data.timeSlide"
      maxlength="3"
    />
    <div :class="$style.slide" v-if="localSlides">
      <div
        :class="$style.wrapper"
        v-for="(s, i) in localSlides"
        :key="i + 'slide'"
      >
        <div :class="$style.left">
          <Input v-model="s.title" placeholder="Введите заголовок слайда" />
          <Textarea
            v-model="s.description"
            placeholder="Введите описание слайда"
          />
          <Input
            v-model="s.photoDescription"
            placeholder="Введите описние к фото"
          />
          <Button type="secondary" @click="close(i)">
            Удалить слайд
          </Button>
        </div>
        <ImageUploader
          v-model="s.photo"
          :class="$style.slider"
          text="Перетащите или нажмите для выбора фото"
        />
      </div>
    </div>
    <Button @click="addSlide()" type="secondary">
      Добавить новый слайд
    </Button>
  </div>
</template>

<script>
import Input from "@/components/atoms/Input";
import Textarea from "@/components/atoms/Textarea";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ImageUploader from "@/components/moleculs/ImageUploader.vue";
import Button from "@/components/atoms/Button.vue";

export default {
  components: {
    Input,
    Textarea,
    ImageUploader,
    Button,
  },
  props: {
    data: Object,
  },

  data(){
    return {
      localSlides: this.data.slides
    }
  },

  methods: {
    addSlide() {
      if (this.data.slides) this.data.slides = [...this.data.slides, {}];
      else this.data.slides = [{}];
      this.localSlides = this.data.slides
    },
    close(index) {
      this.data.slides = this.data.slides.filter((slide, i) => i !== index)
      this.localSlides = this.data.slides
    },
  },
};
</script>

<style lang="scss">
.slick-dots {
  right: 40%;
}

.slick-dots li button:before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background: $black-gray;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  background: $bright-gold;
}
</style>

<style lang="scss" module>
.container {
  background: $white;
  max-width: 82rem;
  padding: 0 1rem 5rem;
  margin: 0 auto;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);

  .slide {
    .wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
    }
    .writeSlide {
      display: flex;
      justify-content: space-between;
    }
    .slider {
      outline: none;
      height: 20rem;
      max-width: 50%;
    }
  }
}
</style>
