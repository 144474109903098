<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <Textarea
        type="primary"
        placeholder="Введите заглавие"
        v-model="data.title"
        maxlength="50"
      />
      <Textarea
        placeholder="Введите описание"
        v-model="data.description"
        maxlength="500"
        rows="7"
      />
      <Button :class="$style.button" type="secondary">
        Задайте вопрос сомелье в
        <adw-icon style="margin-left: 0.5rem" name="telegram" />
      </Button>
      <Textarea
        placeholder="Текст сообщения"
        v-model="data.message"
        maxlength="295"
      />
    </div>
    <div :class="$style.right">
      <ImageUploader
        :class="$style.photo"
        v-model="data.image"
        text="Перетащите или нажмите для выбора фото"
      />
      <Textarea
        :class="$style.descriptionImg"
        placeholder="Описание картинки"
        v-model="data.descriptionImg"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button'
import Textarea from '@/components/atoms/Textarea'
import ImageUploader from '@/components/moleculs/ImageUploader'

export default {
  components: {
    ImageUploader,
    Button,
    Textarea,
  },
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 82rem;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right {
    padding: 1rem 0;
    .descriptionImg {
      height: 3rem;
    }
  }

  .content {
    width: 40%;
  }

  .photo {
    width: 100%;
    height: 33rem;
  }
}
</style>
