<template>
  <div :class="$style.container">
    <Input
      :class="$style.top"
      types="primary"
      placeholder="Введите заглавие"
      maxlength="65"
      v-model="data.title"
    />
    <div :class="$style.addWineFeedbacks">
      <el-checkbox v-model="data.addAddWineFeedbacks"> </el-checkbox>
      <span>Добавить отзывы с AddWine</span>
    </div>
    <FeedbackForm ref="carousel" v-model="data.reviews" />
    <AddWineFeedbackCard
      ref="carousel"
      :showAddWineFeedbacks="data.addAddWineFeedbacks"
    />
  </div>
</template>

<script>
import Input from '@/components/atoms/Input'
import FeedbackForm from '@/components/organisms/FeedbackForm'
import AddWineFeedbackCard from '@/components/moleculs/AddWineFeedbackCard.vue'

export default {
  components: {
    Input,
    FeedbackForm,
    AddWineFeedbackCard
  },
  props: {
    data: Object
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 82rem;
  padding: 0 1rem 5rem;
  margin: 0 auto;

  .top {
    display: flex;
    align-items: center;
    margin: 0 0 2rem;
  }
  .addWineFeedbacks {
    label {
      margin-right: 1rem;
    }
  }
}
</style>
