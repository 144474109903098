<template>
  <div :class="$style.form">
    <Autocomplete
      :search="search"
      placeholder="Поиск товара"
      :valueNames="['name']"
      @querySearch="getProductsList"
      @selectItem="handleSelectProduct"
      @input="search = $event"
      @loadMore="loadMoreProducts"
      :class="$style.autoComplete"
    ></Autocomplete>
    <div :class="$style.cards">
      <div
        v-for="(product, index) of value"
        :key="product.id"
        :class="$style.card"
      >
        <adw-catalog-card
          :title="product.name"
          :is-available="product.isAvailable"
          :img="setupImagesListData(product.images).original"
          :alt="setupImagesListData(product.images).caption"
          :price="product.price"
          :discount-price="product.discountPrice"
          :to="''"
          :delivery="presenceToDeliveryText(product.presence)"
          :self-delivery="presenceToSelfDeliveryText(product.presence)"
          :available-showroom="presenceToShowroomStateText(product.presence)"
        ></adw-catalog-card>
        <div :class="$style.buttons">
          <adw-button :class="$style.button" @click="removeProduct(index)"
            >Удалить</adw-button
          >
          <div :class="$style.arrows">
            <adw-icon
              name="arrow"
              :class="$style.leftIcon"
              @click.native="moveToBack(index)"
            ></adw-icon>
            <adw-icon
              name="arrow"
              :class="$style.rightIcon"
              @click.native="moveToNext(index)"
            ></adw-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from '@/components/atoms/Autocomplete.vue'

import images from '@/mixins/images'
import notifications from '@/mixins/notifications'
import delivery from '@/delivery'
import {
  presenceToShowroomStateText,
  presenceToDeliveryText,
  presenceToSelfDeliveryText,
} from '@/helpers/presenceText'

export default {
  components: { Autocomplete },
  mixins: [images, notifications],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      products: [],
      search: '',
      page: 1,
      limit: 20,
      count: null,
      setSearchItems: null,
    }
  },
  methods: {
    async setProducts(setItems, getOldItems = false) {
      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog({
          isActive: true,
          search: this.search,
          page: this.page,
          limit: this.limit,
        })

      if (error) return

      if (value.products.data.length) {
        value.products.data.push({ isLoading: true })
        this.count = value.products.meta.count
        if (getOldItems) {
          this.products.pop()
          this.products = [...this.products, ...value.products.data]
        } else {
          this.products = [...value.products.data]
        }

        setItems(this.products)
      }
    },
    async getProductsList({ setSearchItems }) {
      this.setSearchItems = setSearchItems
      this.page = 1

      this.setProducts(this.setSearchItems)
    },
    async loadMoreProducts() {
      if (this.limit * this.page < this.count) {
        this.page++

        this.setProducts(this.setSearchItems, true)
      }
    },
    async handleSelectProduct(selectedProduct) {
      if (this.value.some((product) => product.id === selectedProduct.id)) {
        this.showNotification('Товар уже был добавлен', 'error')
        return
      }

      this.$emit('input', [...this.value, selectedProduct])
    },
    presenceToShowroomStateText(presence) {
      return presenceToShowroomStateText(presence)
    },
    presenceToDeliveryText(presence) {
      return presenceToDeliveryText(presence)
    },
    presenceToSelfDeliveryText(presence) {
      return presenceToSelfDeliveryText(presence)
    },
    removeProduct(index) {
      let products = [...this.value]

      products = products.filter((_, indexProduct) => indexProduct !== index)

      this.$emit('input', products)
    },
    moveToNext(index) {
      const addedProducts = [...this.value]
      if (index === addedProducts.length - 1) return
      ;[addedProducts[index], addedProducts[index + 1]] = [
        addedProducts[index + 1],
        addedProducts[index],
      ]
      this.$emit('input', addedProducts)
    },
    moveToBack(index) {
      if (index === 0) return
      const addedProducts = [...this.value]
      ;[addedProducts[index], addedProducts[index - 1]] = [
        addedProducts[index - 1],
        addedProducts[index],
      ]
      this.$emit('input', addedProducts)
    },
  },
}
</script>
<style lang="scss" module>
.form {
  .top {
    display: flex;
    align-items: center;
    margin: 0 0 2rem;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin: 5rem 0;

    .card {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 1rem;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .arrows {
          display: flex;
          align-items: center;

          gap: 1.5rem;
          .leftIcon {
            width: 4rem;
            height: 4rem;
            transform: rotate(90deg);
            cursor: pointer;
          }

          .rightIcon {
            width: 4rem;
            height: 4rem;
            transform: rotate(-90deg);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
