<template>
  <textarea
    :class="[$style.textarea, $style[type]]"
    :placeholder="placeholder"
    autocomplete="off"
    :value="value"
    :rows="rows"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    type: String,
    value: String,
    rows: [
      Number,
      String,
    ],
    placeholder: String,
  },
};
</script>

<style lang="scss" module>
@import "@/assets/styles/colors";
@import "@/assets/styles/fonts";
.textarea {
  width: 100%;
  margin: 0.5rem 0;
  outline: none;
  resize: none;
  background: $smoky;
  border: none;
  @include text;

  &.primary {
    @include title;
    margin: 1rem 0 0;
  }
}
.textarea::placeholder {
  color: $light-gray;
}
</style>
