<template>
  <div :class="$style.container">
    <h1 :class="$style.title">
      Всего 4 шага и
      <Input
        :class="$style.inputFourthStep"
        types="primary"
        placeholder="Введите название"
        v-model="data.description"
        maxlength="62"
      />
    </h1>
    <div :class="$style.items">
      <div :class="$style.item" v-for="item in items" :key="item.text">
        <Icon :name="item.icon" />
        <p>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/atoms/Input";
import Icon from "@/components/atoms/Icon";

export default {
  components: {
    Icon,
    Input,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      items: [
        {
          text: "1. Оформление заявки",
          icon: "arm",
        },
        {
          text: "2. Звонок менеджера для подтверждения заказа",
          icon: "dispatcher",
        },
        {
          text: "3. Самовывоз или доставка",
          icon: "fastDelivery",
        },
        {
          text: "4. Любая форма оплаты",
          icon: "payment",
        },
      ],
    };
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  background: $banner;
  max-width: 82rem;
  padding: 3rem 1rem;
  margin: 0 auto;

  .title {
    font-size: 1.75rem;
    color: $white;
    position: relative;
    padding-bottom: 2rem;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: $extra-light-gray;
      position: absolute;
      bottom: 0;
    }

    .inputFourthStep {
      width: 83.5%;
      color: $white;
      margin: 0;
      background: transparent;
    }
  }

  .items {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      padding: 1rem;

      p {
        margin: 1rem 0 0;
        text-align: center;
        color: $white;
      }
    }
  }

}
</style>
