<template>
  <div :class="$style.input">
    <div :class="$style.wrapper">
      <textarea
        v-if="textarea"
        :class="{ [$style.error]: error, [$style.success]: success }"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      >
      </textarea>
      <input
        v-else
        :class="{ [$style.error]: error, [$style.success]: success }"
        :type="type"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      />
      <label class="label">{{ placeholder }}</label>
    </div>
    <div
      v-if="error || success"
      :class="[
        $style.message,
        { [$style.error]: error, [$style.success]: success },
      ]"
    >
      {{ error || success }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        ['text', 'number', 'email', 'password'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';
@import '@/assets/styles/mixins.scss';

.input {
  .message {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: right;
    &.error {
      color: $error;
    }
    &.success {
      color: $complite;
    }
  }
  .wrapper {
    position: relative;
    label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; // For textareas
      color: $dark-gray;
      padding: 0 1.5rem;
      font-size: 1rem;
      pointer-events: none;
      border: 0.063rem solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      transition: transform 0.2s ease-in-out;
      font-weight: normal;
      z-index: 50;
    }
    input {
      height: 4.5rem;
    }
    textarea {
      min-height: 7.5rem;
      resize: vertical;
    }
    input,
    textarea {
      @include input;
      &::placeholder {
        color: $dark-gray;
      }
      &:disabled,
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
      &:focus {
        border-color: $light-gray;
      }
      &.error {
        border-color: $error;
      }
      &.success {
        border-color: $complite;
      }
      &:disabled,
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
      &:focus {
        border-color: $light-gray;
      }
      &.error {
        border-color: $error;
      }
      &.success {
        border-color: $complite;
      }
      &::placeholder {
        color: transparent;
      }
      // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
      &:-webkit-autofill {
        padding: 2.25rem 1.5rem 0.75rem;
        ~ label {
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 1rem;
          transform: translateY(-0.75rem);
        }
      }
      &:focus,
      &:not(:placeholder-shown) {
        padding: 2.25rem 1.5rem 0.75rem;
        ~ label {
          font-size: 0.75rem;
          line-height: 1rem;
          transform: translateY(-0.75rem);
        }
      }
    }
  }
}
</style>
