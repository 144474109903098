<template>
  <label class="label" for="gallery">
    <input
      :class="$style.inputFile"
      type="file"
      id="gallery"
      @change="uploadImages"
      multiple
    />
    {{ text }}
  </label>
</template>

<script>
import UC from '@/usecases'

export default {
  name: 'ImageBulkUploader',
  props: {
    text: {
      type: String,
      default: 'Загрузить фотографии в галерею'
    }
  },
  methods: {
    async uploadImages(event) {
      const { value } = await UC.ImageService.uploadBulkFiles(
        event.target.files
      )

      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" module>
.inputFile {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
</style>
