var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.to)?_c('nuxt-link',{class:[
    _vm.$style.button,
    _vm.$style[_vm.color],
    {
      [_vm.$style.small]: _vm.small,
      [_vm.$style.disabled]: _vm.disabled,
    },
  ],attrs:{"to":_vm.to}},[_vm._t("default")],2):(_vm.href)?_c('a',{class:[
    _vm.$style.button,
    _vm.$style[_vm.color],
    {
      [_vm.$style.small]: _vm.small,
      [_vm.$style.disabled]: _vm.disabled,
    },
  ],attrs:{"href":_vm.href}},[_vm._t("default")],2):_c('button',{class:[_vm.$style.button, _vm.$style[_vm.color], { [_vm.$style.small]: _vm.small }],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }