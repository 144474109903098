<template>
  <div :class="$style.app">
    <LandingSectionWrapper
      title="Системные конфигурации"
      v-model="systemSection.isOpen"
    >
      <LandingSection type="System" :data="systemSection" />
    </LandingSectionWrapper>
    <Container @drop="reorderSections" drag-handle-selector="#drag-handler">
      <Draggable
        tag="template"
        v-for="(section, index) in sections"
        :key="section.type + index"
      >
        <LandingSectionWrapper
          v-model="section.isOpen"
          :title="LandingSectionsList[section.type]"
          @close="sections.splice(index, 1)"
        >
          <LandingSection :type="section.type" :data="section.data" />
        </LandingSectionWrapper>
      </Draggable>
    </Container>

    <div :class="$style.buttons">
      <Button
        v-for="landingSection in Object.keys(LandingSectionsList)"
        :key="landingSection"
        type="secondary"
        @click="
          sections.push({
            type: landingSection,
            data: {},
          })
        "
      >
        + {{ LandingSectionsList[landingSection] }}
      </Button>
    </div>

    <div :class="$style.systemButtons">
      <Button
        type="secondary"
        @click="$router.back()"
        title="Выйти без сохранения"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="xmark"
          class="svg-inline--fa fa-xmark"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
          ></path>
        </svg>
      </Button>
      <template v-if="!isNew">
        <Button
          type="primary"
          @click="saveLanding(true)"
          title="Сохранить и выйти"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            class="svg-inline--fa fa-check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z"
            ></path>
          </svg>
        </Button>
        <Button type="primary" @click="saveLanding" title="Сохранить">
          <Icon name="save" />
        </Button>
      </template>
      <Button v-else type="primary" @click="createLanding" title="Создать">
        <Icon name="plus" />
      </Button>
    </div>

    <transition name="modal">
      <InfoModalWindow
        v-if="this.$store.state.modal.info"
        :class="$style.modal"
      />
    </transition>
    <transition name="modal">
      <SuccessModal
        v-if="this.$store.state.modal.publication"
        :class="$style.modal"
      />
    </transition>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import { LandingSectionsList } from '@/components/organisms/landingSections'
import { LANDING_SECTIONS_TYPE } from '@/constants/landingSectionsType'
import { LANDING_ROUTES } from '@/constants/routing'

import Button from '@/components/atoms/Button'
import LandingSection from '@/components/organisms/landingSections/IndexSection'
import LandingSectionWrapper from '@/components/organisms/LandingSectionWrapper'
import InfoModalWindow from '@/components/atoms/InfoModalWindow'
import SuccessModal from '@/components/atoms/SuccessModal'
import Icon from '@/components/atoms/Icon'

export default {
  name: 'App',
  LANDING_SECTIONS_TYPE,
  components: {
    Button,
    LandingSectionWrapper,
    LandingSection,
    InfoModalWindow,
    SuccessModal,
    Icon,
  },
  mixins: [notifications],
  data: () => ({
    id: null,
    systemSection: {},
    sections: [],
    LandingSectionsList,
  }),
  async mounted() {
    if (!this.isNew) {
      this.getContent()
    }
  },
  computed: {
    isNew() {
      return this.$route.path.split('/').at(-1) === 'create'
    },
  },
  methods: {
    async getContent() {
      const landingId = this.$route.params.id
      const { value, error } =
        await delivery.LandingsCore.LandingActions.getLandingById(landingId)
      if (error) {
        this.$router.push(LANDING_ROUTES.LIST)
        return
      }
      this.id = value.id
      this.sections =
        value.sections?.map((section) => {
          return {
            isOpen: section.isOpen || false,
            ...section,
          }
        }) ?? []
      this.systemSection = {
        isOpen: value.systemSectionIsOpen,
        name: value.name,
        link: value.link,
        favicon: value.favicon,
        seoTitle: value.seoTitle,
        seoDescription: value.seoDescription,
        seoKeywords: value.seoKeywords,
        seoImage: value.seoImage,
        gaTag: value.gaTag,
        ymTag: value.ymTag,
        roistatTag: value.roistatTag,
        mailOnOrder: value.mailOnOrder || false,
      }
      const { data } = this?.sections?.find((section) => {
        return section.type == LANDING_SECTIONS_TYPE.FEEDBACKS
      })
      if (!data?.reviewsConverted) {
        const oldReviews =
          data?.feedbacks?.map((feedback) => {
            const socials = this.getSocials(feedback)
            return {
              image: feedback?.photo,
              name: feedback?.name,
              jobTitle: feedback?.position,
              text: feedback?.feedback,
              socials,
            }
          }) || []
        data.reviews = data?.reviews || []
        data.reviews.push(...oldReviews)
        data.reviewsConverted = true
      }
    },
    getSocials(item) {
      const socials = []
      Object.keys(item).forEach(
        (social) =>
          ['instagram', 'facebook', 'youtube', 'telegram'].includes(social) &&
          socials.push({ iconName: social, link: item[social] }),
      )
      return socials
    },
    reorderSections(dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return

      const result = [...this.sections]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      this.sections = result
    },
    validate(data) {
      let isValid = true
      if (
        !(
          data.name?.length &&
          regexp.landing_name.test(data.name) &&
          !regexp.landing_name_exclude.test(data.name)
        )
      ) {
        setTimeout(() => {
          this.showNotification(
            'Название бренда должно быть написано латиницей, в одно слово с возможностью использовать символы "-", "_", строчными буквами, исключая системные слова(dev, test, addwine, localhost, new, undefined)',
            'error',
          )
        })
        isValid = false
      }
      if (!data.seoTitle?.length) {
        setTimeout(() => {
          this.showNotification('Поле SeoTitle должно быть заполнено', 'error')
        })
        isValid = false
      }
      if (!data.favicon?.length) {
        setTimeout(() => {
          this.showNotification('Поле favicon должно быть заполнено', 'error')
        })
        isValid = false
      }
      return isValid
    },
    async createLanding() {
      if (!this.validate(this.systemSection)) {
        return
      }
      this.sections?.forEach((section) => {
        if (section.type !== LANDING_SECTIONS_TYPE.PRODUCTORDERIII) {
          return
        }
        const productsIds = section.data.products.map((product) => product.id)
        section.data.productIds = productsIds
      })
      const { error } =
        await delivery.LandingsCore.LandingActions.createLanding({
          ...this.systemSection,
          systemSectionIsOpen: this.systemSection.isOpen,
          sections: this.sections,
        })
      if (error) {
        return
      }
      this.showNotification('Лендинг успешно создан', 'success')
      this.$router.push(LANDING_ROUTES.LIST)
    },
    async saveLanding(exit = false) {
      if (!this.validate(this.systemSection)) {
        return
      }
      this.sections?.forEach((section) => {
        if (section.type !== LANDING_SECTIONS_TYPE.PRODUCTORDERIII) {
          return
        }
        const productsIds = section.data.products.map((product) => product.id)
        section.data.productIds = productsIds
      })
      const { error } =
        await delivery.LandingsCore.LandingActions.updateLanding(this.id, {
          ...this.systemSection,
          systemSectionIsOpen: this.systemSection.isOpen,
          sections: this.sections,
        })
      if (error) {
        return
      }
      this.showNotification('Лендинг успешно отредактирован', 'success')
      if (exit) {
        this.$router.push(LANDING_ROUTES.LIST)
      }
    },
  },
}
</script>

<style lang="scss" module>
.app {
  .buttons {
    max-width: 82rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin: 1.5rem;
    }
  }
  .systemButtons {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    > button {
      width: 3.13rem;
      height: 3.13rem;
      border-radius: 50%;
      border: 0.12rem solid $smoky;
      svg {
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        left: 50%;
        margin-left: -0.625rem;
        margin-top: -0.625rem;
        fill: $white;
      }
      &:last-child {
        width: 3.75rem;
        height: 3.75rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  .modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0px;
  }
}
</style>
