<template>
  <div :class="$style.common">
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <div :class="$style.addElem">
        <h3>Отзывы</h3>
        <el-button
          size="small"
          type="primary"
          :class="$style.button"
          @click="addReviewElem()"
        >
          Добавить отзыв
        </el-button>
      </div>
      <Container
        @drop="onDropReviews"
        :non-drag-area-selector="'.drag-disabled'"
      >
        <Draggable v-for="(menuItem, index) in value" :key="index">
          <div :class="$style.reviewers">
            <el-button
              type="danger"
              plain
              circle
              icon="el-icon-delete"
              :class="$style.remove"
              @click="removeItem(index)"
            />
            <div>
              <el-form-item
                label="Имя"
                class="drag-disabled"
                :prop="'reviews.' + index + '.name'"
              >
                <el-input v-model="menuItem.name" />
              </el-form-item>
              <el-form-item
                label="Место работы"
                class="drag-disabled"
                :prop="'reviews.' + index + '.jobTitle'"
              >
                <el-input v-model="menuItem.jobTitle" />
              </el-form-item>
              <el-form-item
                label="Отзыв"
                class="drag-disabled"
                :prop="'reviews.' + index + '.text'"
              >
                <el-input
                  v-model="menuItem.text"
                  :rows="7"
                  type="textarea"
                  placeholder="Введите отзыв"
                />
              </el-form-item>
            </div>

            <div>
              <div :class="$style.addElem">
                <h3>Ссылки на соцсети</h3>
                <el-button
                  size="small"
                  type="primary"
                  :class="$style.button"
                  @click="addIconForReview(index)"
                  >Добавить ссылку</el-button
                >
              </div>
              <Container
                @drop="onDropReviewSocials(index, $event)"
                :non-drag-area-selector="'.drag-disabled'"
              >
                <Draggable v-for="(icon, ind) in menuItem.socials" :key="ind">
                  <div :class="$style.draggableSocials">
                    <el-form-item
                      label="Ссылка"
                      label-width="5rem"
                      class="drag-disabled"
                      :prop="'reviews.' + index + '.socials.' + ind + '.link'"
                    >
                      <el-input v-model="icon.link" />
                    </el-form-item>
                    <el-form-item
                      label="Иконка"
                      label-width="5rem"
                      class="drag-disabled"
                    >
                      <el-select
                        v-model="icon.iconName"
                        filterable
                        placeholder="Выберите иконку"
                        :popper-class="$style.iconOptions"
                      >
                        <el-option
                          v-for="(item, index) in iconOptions"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                          <adw-icon :name="item" />
                          {{ item }}
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <div :class="$style.removeButton">
                      <el-button
                        type="danger"
                        plain
                        circle
                        icon="el-icon-delete"
                        :class="$style.remove"
                        @click="removeReviewSocials(menuItem.socials, ind)"
                      />
                    </div>
                    <div :class="$style.dragIcon">
                      <Icon name="draggable" :class="$style.icon" />
                    </div>
                  </div>
                </Draggable>
              </Container>
            </div>

            <div :class="$style.uploaderWrapper">
              <Uploader
                without-caption
                :limit="1"
                :files="
                  menuItem.image
                    ? [
                        {
                          original: value[index].image,
                        },
                      ]
                    : []
                "
                @upload="uploadImages($event, index)"
              />
              <div :class="$style.centerIcon">
                <Icon name="draggable" :class="$style.icon" />
              </div>
            </div>
          </div>
        </Draggable>
      </Container>
    </el-form>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import Uploader from '@/components/moleculs/AddwineUploader.vue'

export default {
  components: {
    Icon,
    Uploader,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slideToScroll: 1,
        speed: 700,
      },
      iconOptions: [
        'instagram',
        'facebook',
        'youtube',
        'telegram',
        'whatsAppLogo',
      ],
    }
  },
  methods: {
    uploadImages(images, index) {
      this.value[index].image = images.length ? images[0].original : ''
    },
    addReviewElem() {
      let items = [...this.value]
      items.unshift({
        name: '',
        jobTitle: '',
        image: '',
        text: '',
        socials: [],
      })
      this.$emit('input', items)
    },
    addIconForReview(index) {
      let items = this.value
      items[index].socials.push({
        iconName: '',
        link: '',
      })
      this.$emit('input', items)
    },
    removeReviewSocials(socials, index) {
      socials.splice(index, 1)
    },
    removeItem(index) {
      let items = this.value
      items = items.filter((item, i) => i !== index)
      this.$emit('input', items)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropReviews(dropResult) {
      let items = this.value
      items = this.applyDrag(this.value, dropResult)
      this.$emit('input', items)
    },
    onDropReviewSocials(index, dropResult) {
      let items = this.value
      items[index].socials = this.applyDrag(items[index].socials, dropResult)
      this.$emit('input', items)
    },
  },
}
</script>

<style lang="scss" module>
.iconOptions > div > div > ul {
  li {
    padding-top: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      path {
        fill: $gray;
      }
    }
  }
}

.common {
  .form {
    padding: 2rem;
    .dropdownBlock > div:last-child {
      .title {
        h3 {
          width: 10rem;
        }
        display: flex;
        .icon {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.5rem;
          cursor: pointer;
          &.arrow {
            margin-right: 1rem;
            transition: transform 0.3s ease;
            transform: rotate(-180deg);
            &.active {
              transition: transform 0.3s ease;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
  .uploaderWrapper {
    display: flex;
  }
  .draggableSocials {
    display: flex;
    padding: 0.5rem 0;
  }
  .removeButton {
    padding-left: 1rem;
  }
  .reviewers {
    display: grid;
    grid-template-columns: 1rem 2fr 2.5fr 0.5fr;
    .remove {
      margin-top: 3rem;
      width: 3rem;
      height: 3rem;
    }
  }
  .centerIcon {
    margin-top: calc(50% - 1.25rem);
  }
  .dragIcon {
    margin-left: 1rem;
  }
  .addElem {
    display: flex;
    align-items: center;
    padding: 0 0 1rem 1rem;
    h3 {
      padding-right: 1rem;
    }
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .name {
    text-align: center;
    padding: 1rem 0;
  }

  .menuBlock {
    .menuItem {
      display: flex;
      align-items: center;

      input {
        width: 70vw;
      }

      .removeElem {
        margin-top: -1rem;
        padding: 0 1rem;
      }
    }
  }
}
</style>
