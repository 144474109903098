<template>
  <div :class="[$style.photo, { [$style.border]: !value }]">
    <input
      :class="$style.input"
      type="file"
      :multiple="multiple"
      @change="changeImage"
    />
    <template v-if="!value">
      <Icon name="plus" :class="$style.plus" />
      <h2>{{ text }}</h2>
    </template>
    <template v-else>
      <VueSlickCarousel
        v-if="showCarousel"
        v-bind="options"
        :class="$style.carousel"
      >
        <img
          v-for="(image, index) in value"
          :key="index"
          :class="$style.image"
          :src="$configData.s3_link + image"
        />
      </VueSlickCarousel>
      <img v-else :class="$style.image" :src="$configData.s3_link + value" />
    </template>
  </div>
</template>

<script>
import UC from '@/usecases'
import Icon from '@/components/atoms/Icon'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'ImageUploader',
  components: { Icon, VueSlickCarousel },
  props: {
    value: [String, Array],
    text: String,
    multiple: {
      type: Boolean,
      default: false
    },
    autoplaySpeed: {
      type: Number,
      default: 3000
    }
  },
  computed: {
    options() {
      return {
        arrows: false,
        autoplay: true,
        autoplaySpeed: this.autoplaySpeed
      }
    },
    showCarousel() {
      return this.multiple && Array.isArray(this.value) && this.value.length > 1
    }
  },
  methods: {
    async changeImage(e) {
      const { value, error } = !this.multiple
        ? await UC.ImageService.uploadFile(e.target.files)
        : await UC.ImageService.uploadBulkFiles(e.target.files)

      if (!error && value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" module>
.photo {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  .plus {
    fill: $light-gray;
    width: 2rem;
  }
  .input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .image {
    object-fit: cover;
  }
  .image,
  .carousel {
    width: 100%;
    height: 100%;
  }
}
.border {
  border: 3px dashed $light-gray;
}
</style>
