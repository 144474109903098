<template>
  <div :class="$style.gallery">
    <LightGallery
      v-if="data.photos"
      :images="data.photos.map((photo) => s3_link + photo)"
      :index="index"
      :disable-scroll="true"
      @close="index = null"
    />
    <div v-if="data.photos" :class="$style.content">
      <div :class="$style.left">
        <div :class="$style.top">
          <img
            :class="$style.img"
            :src="setupImageData(data.photos[0]).original"
            @click="setIndexByClick(0)"
          />
        </div>
        <div :class="$style.bottom">
          <img
            :class="$style.img"
            :src="setupImageData(data.photos[1]).original"
            @click="setIndexByClick(1)"
          />
        </div>
      </div>
      <div :class="$style.right">
        <div :class="$style.wrapper">
          <div :class="$style.wrapperThird">
            <img
              :class="$style.img"
              :src="setupImageData(data.photos[2]).original"
              @click="setIndexByClick(2)"
            />
          </div>
          <div :class="$style.wrapperFourth">
            <img
              :class="$style.img"
              :src="setupImageData(data.photos[3]).original"
              @click="setIndexByClick(3)"
            />
          </div>
        </div>
        <div :class="$style.fifthWrapper">
          <img
            :class="$style.img"
            :src="setupImageData(data.photos[4]).original"
            @click="setIndexByClick(4)"
          />
        </div>
      </div>
    </div>
    <div v-if="data.photos" :class="$style.textarea">
      <Textarea
        :class="$style.description"
        placeholder="Описание первой картинки"
        v-model="data.descriptionImgNul"
        rows="4"
      />
      <Textarea
        :class="$style.description"
        placeholder="Описание второй картинки"
        v-model="data.descriptionImgOne"
        rows="4"
      />
      <Textarea
        :class="$style.description"
        placeholder="Описание тpeтьeй картинки"
        v-model="data.descriptionImgTwo"
        rows="4"
      />
      <Textarea
        :class="$style.description"
        placeholder="Описание четвертой картинки"
        v-model="data.descriptionImgThree"
        rows="4"
      />
      <Textarea
        :class="$style.description"
        placeholder="Описание пятой картинки"
        v-model="data.descriptionImgFour"
        rows="4"
      />
    </div>
    <div :class="$style.buttons">
      <ImageBulkUploader v-model="data.photos" />
      <transition name="disappearance">
        <Button
          v-if="data.photos && data.photos.length >= 6"
          color="dark"
          border="black"
          @click="index = 5"
        >
          Показать еще фото
        </Button>
      </transition>
    </div>
  </div>
</template>

<script>
import Textarea from '@/components/atoms/Textarea'
import Button from '@/components/atoms/Button'
import { LightGallery } from 'vue-light-gallery'
import ImageBulkUploader from '@/components/moleculs/ImageBulkUploader'
import images from '@/mixins/images'

export default {
  mixins: [images],
  components: {
    ImageBulkUploader,
    LightGallery,
    Button,
    Textarea
  },

  data() {
    return {
      index: null
    }
  },

  props: {
    data: Object
  },

  computed: {
    s3_link() {
      return process.env.VUE_APP_S3_URL
    }
  },
  methods: {
    setIndexByClick(i) {
      return (this.index = this.data.photos.length > i ? i : this.index)
    }
  }
}
</script>

<style lang="scss" module>
.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 67rem;

  .content {
    width: 82rem;
    height: 55rem;
    padding: 0 1rem 2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      width: 29rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {
        display: flex;
        width: 100%;
        height: 32.5rem;
        background: $dark-gray;
      }

      .bottom {
        display: flex;
        width: 100%;
        height: 19rem;
        background: $dark-gray;
      }
    }

    .right {
      width: 49.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 28.5%;

        .wrapperThird {
          width: 48.5%;
          background: $dark-gray;
        }

        .wrapperFourth {
          width: 48.5%;
          background: $dark-gray;
        }
      }
      .fifthWrapper {
        display: flex;
        width: 100%;
        height: 69%;
        background: $dark-gray;
      }
    }

    .img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: brightness(0.6);
        cursor: pointer;
      }
    }
  }
  .textarea {
    display: flex;
    justify-content: center;
    width: 55rem;
    .description {
      width: 11%;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
  }
}
</style>
