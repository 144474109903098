<template>
  <nuxt-link
    v-if="to"
    :to="to"
    :class="[
      $style.button,
      $style[color],
      {
        [$style.small]: small,
        [$style.disabled]: disabled,
      },
    ]"
  >
    <slot />
  </nuxt-link>
  <a
    v-else-if="href"
    :href="href"
    :class="[
      $style.button,
      $style[color],
      {
        [$style.small]: small,
        [$style.disabled]: disabled,
      },
    ]"
  >
    <slot />
  </a>
  <button
    v-else
    :class="[$style.button, $style[color], { [$style.small]: small }]"
    :type="type"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit', 'reset'].includes(value),
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'outline-secondary'].includes(value),
    },
    small: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.button {
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 1.625rem 1.5rem;
  transition: all 0.2s ease-in-out;
  &.small {
    padding: 0.875rem 1.5rem;
  }
  &:disabled,
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.primary {
    color: $white;
    background: $bright-gold;
    &:hover,
    &:active {
      background: $dark-orange;
    }
  }
  &.secondary {
    color: $white;
    background: $light-gray;
    &:hover,
    &:active {
      background: $dark-gray;
    }
  }
  &.outline-secondary {
    background: transparent;
    color: $dark-gray;
    border: 0.063rem solid $light-gray;
    &:hover {
      color: $black-gray;
      border-color: $black-gray;
    }
    &:active {
      background: $smoky;
      color: $dark-gray;
      border-color: $light-gray;
    }
  }
}
</style>
