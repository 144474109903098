import { PRESENCES } from '@/constants/productPresences'

export const presenceToShowroomStateText = (presence) => {
  switch (presence) {
    case PRESENCES.SHOWROOM:
    case PRESENCES.MAIN_WAREHOUSE:
      return 'В наличии в шоуруме'
    default:
      return ''
  }
}

export const presenceToDeliveryText = (presence) => {
  const currentDay = new Date().getDay()
  const currentHour = new Date().getHours()
  const isAvailable =
    presence === PRESENCES.MAIN_WAREHOUSE ||
    presence === PRESENCES.SHOWROOM ||
    presence === PRESENCES.REMOTE_WAREHOUSE

  if (isAvailable) {
    if (presence === PRESENCES.REMOTE_WAREHOUSE) {
      switch (true) {
        case currentDay === 0:
          return 'Доставим послезавтра'
        case currentDay === 4 && currentHour >= 14:
          return 'Доставим через 3 дня'
        case currentDay === 5 && currentHour < 14:
          return 'Доставим через 2 дня'
        case currentDay === 5 && currentHour >= 14:
          return 'Доставим через 3 дня'
        case currentDay === 6:
          return 'Доставим через 2 дня'
        default:
          if (currentHour < 14) {
            return 'Доставим завтра'
          } else {
            return 'Доставим послезавтра'
          }
      }
    } else {
      switch (true) {
        case currentDay === 5 && currentHour >= 20:
          return 'Доставим послезавтра'
        case currentDay === 6:
          return 'Доставим завтра'
        case currentHour < 9:
          return 'Доставим сегодня после 09:00'
        case currentHour < 18:
          return 'Доставим через 2-3 часа'
        case currentHour < 20:
          return 'Доставим завтра'
        default:
          return 'Доставим завтра'
      }
    }
  }

  return ''
}

export const presenceToSelfDeliveryText = (presence) => {
  const currentDay = new Date().getDay()
  const currentHour = new Date().getHours()
  const isAvailable =
    presence === PRESENCES.MAIN_WAREHOUSE ||
    presence === PRESENCES.SHOWROOM ||
    presence === PRESENCES.REMOTE_WAREHOUSE

  if (isAvailable) {
    if (presence === PRESENCES.REMOTE_WAREHOUSE) {
      switch (true) {
        case currentDay === 0:
          return 'Самовывоз послезавтра'
        case currentDay === 4 && currentHour >= 14:
          return 'Самовывоз через 3 дня'
        case currentDay === 5 && currentHour < 14:
          return 'Самовывоз через 2 дня'
        case currentDay === 5 && currentHour >= 14:
          return 'Самовывоз через 3 дня'
        case currentDay === 6:
          return 'Самовывоз через 2 дня'
        default:
          if (currentHour < 14) {
            return 'Самовывоз завтра c 17 до 20'
          } else {
            return 'Самовывоз послезавтра'
          }
      }
    } else {
      switch (true) {
        case currentDay === 5 && currentHour >= 20:
          return 'Самовывоз послезавтра'
        case currentDay === 6:
          return 'Самовывоз завтра'
        case currentHour < 9:
          return 'самовывоз сегодня с 09:00 до 20:00'
        case currentHour < 18:
          return 'Самовывоз сейчас'
        case currentHour < 20:
          return 'Самовывоз сейчас'
        default:
          return 'Самовывоз завтра'
      }
    }
  }

  return ''
}
