<template>
  <el-autocomplete
    :value="search"
    :placeholder="placeholder"
    :fetch-suggestions="querySearch"
    :clearable="clearable"
    @select="handleSelectItem"
    @keyup.enter.native="handleFindItems"
    :popper-class="$style.autocomplete"
    @input="(value) => $emit('input', value)"
  >
    <template slot-scope="{ item }">
      <div v-if="item.isLoading" v-intersection="loadMore"></div>
      <div :class="$style.item">
        <img
          v-if="item.images"
          :src="item.images && $configData.s3_link + item.images[0].original"
          alt=""
          :class="$style.img"
        />
        <div v-if="valueNames.length">
          <span
            v-for="(valueName, index) in valueNames"
            :key="item[valueName] + index"
          >
            {{ item[valueName] }}
          </span>
        </div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
export default {
  props: {
    placeholder: { type: String, default: '' },
    search: { type: String, default: '' },
    valueNames: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: false }
  },
  methods: {
    querySearch(queryString, setSearchItems) {
      this.$emit('querySearch', { queryString, setSearchItems })
    },
    handleSelectItem(selectItem) {
      this.$emit('selectItem', selectItem)
    },
    handleFindItems() {
      this.$emit('handleFindItems')
    },
    loadMore() {
      this.$emit('loadMore')
    }
  }
}
</script>

<style lang="scss" module>
.autocomplete {
  width: auto !important;
  .item {
    display: flex;
    align-items: center;
    .img {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
    }
  }
}
</style>
